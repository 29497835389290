import * as React from "react";
import * as styles from "./how-to-order.module.css";

import { FunctionComponent, useCallback, useState } from "react";

import HeroContainer from "../components/hero-container";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import { Text } from "@chakra-ui/react";
import { navigate } from "gatsby";

const HowToOrder: FunctionComponent = () => {
  const onDarkButtonClick = useCallback(() => {
    navigate("/shop");
  }, []);
  return (
    <>
      <Layout>
        <div className={styles.howtoorder}>
          <HeroContainer
            heroImage="/dm-howtoorder-hero.webp"
            heroTitle="How To Order"
            sectionDescription="Doctors and other appropriately-licensed medical professionals can order wholesale medical supplies from DoctorMedica."
            arrowImage="/remixiconslinesystemarrowdownsline3.svg"
            url="/how-to-order/#how"
            heroSubTitle={""}
          />
          <div id="anchorlink" className={styles.howtoslot}>
            <div className={styles.howtoordersection}>
              <div className={styles.salestextbox}>
                <div className={styles.p}>
                  <Text className={styles.toPlaceAn}>
                    To place an order, first register an account and include
                    your medical license number and other details. Once we have
                    verified your medical license and approved your account, you
                    are ready to order. There are four easy ways to order:
                  </Text>
                </div>
              </div>
              <div className={styles.howtorow}>
                <div className={styles.howtocolum}>
                  <div className={styles.orderonline}>
                    <div className={styles.feature}>
                      <img
                        className={styles.deviceLaptopIcon}
                        alt="devicelaptop"
                        src="/devicelaptop.svg"
                      />
                      <div className={styles.split} />
                      <Text className={styles.orderOnline}>Order Online</Text>
                    </div>
                    <div className={styles.p1}>
                      <Text className={styles.toPlaceAn}>
                        Use the menu or search function to navigate to the
                        product you want. Select a quantity, add the item to
                        your cart, and check out. We accept credit card payments
                        in U.S. dollars.
                      </Text>
                    </div>
                  </div>
                  <button className={styles.darkButton}>
                    <b className={styles.label} onClick={onDarkButtonClick}>
                      VIEW ALL PRODUCTS
                    </b>
                  </button>
                </div>
                <div className={styles.howtocolum1}>
                  <div className={styles.orderonline}>
                    <div className={styles.feature}>
                      <img
                        className={styles.deviceLaptopIcon}
                        alt="phone"
                        src="/phone10.svg"
                      />
                      <div className={styles.split} />
                      <Text className={styles.orderOnline}>Order By Phone</Text>
                    </div>
                    <div className={styles.p2}>
                      <div className={styles.toPlaceAn}>
                        <a className={styles.a} href="tel:1-866-343-2413">
                          +1-866-343-2413
                        </a>
                      </div>
                    </div>
                  </div>
                  <button className={styles.darkButton1}>
                    <Text fontWeight={700} className={styles.label}>
                      <a href="tel:1-866-343-2413" className={styles.label}>
                        CALL US
                      </a>
                    </Text>
                  </button>
                </div>
              </div>
              <div className={styles.howtorow}>
                <div className={styles.howtocolum1}>
                  <div className={styles.orderonline}>
                    <div className={styles.feature}>
                      <img
                        className={styles.deviceLaptopIcon}
                        alt="mail"
                        src="/mail11.svg"
                      />
                      <div className={styles.split} />
                      <div className={styles.orderOnline}>Order By Email</div>
                    </div>
                    <div className={styles.p2}>
                      <div className={styles.toPlaceAn}>
                        <a
                          className={styles.a}
                          href="mailto:info@doctormedica.co"
                        >
                          info@doctormedica.co
                        </a>
                      </div>
                    </div>
                  </div>
                  <button className={styles.darkButton2}>
                    <Text fontWeight={700} className={styles.label}>
                      <a
                        href="mailto:info@doctormedica.co"
                        className={styles.label}
                      >
                        SEND US AN EMAIL
                      </a>
                    </Text>
                  </button>
                </div>
                <div className={styles.howtocolum3}>
                  <div className={styles.feature}>
                    <img
                      className={styles.deviceLaptopIcon}
                      alt="filephone"
                      src="/filephone.svg"
                    />
                    <div className={styles.split} />
                    <Text className={styles.orderOnline}>Order By Fax</Text>
                  </div>
                  <div className={styles.p1}>
                    <Text className={styles.toPlaceAn}>
                      Once we have your credit card authorization form on file,
                      you can send your order by fax at any time.
                    </Text>
                  </div>
                </div>
              </div>
              <div className={styles.textbox}>
                <div className={styles.p5}>
                  <div className={styles.toPlaceAn}>
                    <Text className={styles.noTimeTo}>
                      No time to place the order yourself? No problem! You can
                      authorize someone else to order on your behalf, such as
                      your medical secretary or clinic manager. Speak to one of
                      our customer service representatives to receive an
                      authorization form. Once you fill out this form and send
                      it back, the person you designate can place orders on your
                      behalf. You can notify us to revoke this authorization at
                      any time.
                    </Text>
                    <p className={styles.noTimeTo}>&nbsp;</p>
                    <p className={styles.readyToOrder}>
                      Ready to order wholesale medical supplies and save? Begin
                      by registering a new account now.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="How to Order | Doctor Medica Medical Supply Store"
    description="Learn how to order wholesale medical supplies from Doctor Medica online or by phone, fax, email. Register a new account to place your first order and save."
  />
);
export default HowToOrder;
