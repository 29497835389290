// extracted by mini-css-extract-plugin
export var a = "how-to-order-module--a--f6e44";
export var darkButton = "how-to-order-module--darkButton--ee801";
export var darkButton1 = "how-to-order-module--darkButton1--f671b";
export var darkButton2 = "how-to-order-module--darkButton2--79ab9";
export var deviceLaptopIcon = "how-to-order-module--deviceLaptopIcon--8544c";
export var feature = "how-to-order-module--feature--ec41e";
export var howtocolum = "how-to-order-module--howtocolum--366ce";
export var howtocolum1 = "how-to-order-module--howtocolum1--e577d";
export var howtocolum3 = "how-to-order-module--howtocolum3--4145c";
export var howtoorder = "how-to-order-module--howtoorder--f997b";
export var howtoordersection = "how-to-order-module--howtoordersection--72fba";
export var howtorow = "how-to-order-module--howtorow--1bfd3";
export var howtoslot = "how-to-order-module--howtoslot--9abab";
export var label = "how-to-order-module--label--f235b";
export var noTimeTo = "how-to-order-module--noTimeTo--69e3c";
export var orderOnline = "how-to-order-module--orderOnline--92062";
export var orderonline = "how-to-order-module--orderonline--86d4f";
export var p = "how-to-order-module--p--6fc5e";
export var p1 = "how-to-order-module--p1--f132a";
export var p2 = "how-to-order-module--p2--261b9";
export var p5 = "how-to-order-module--p5--a2567";
export var readyToOrder = "how-to-order-module--readyToOrder--23b46";
export var salestextbox = "how-to-order-module--salestextbox--cc18d";
export var split = "how-to-order-module--split--2b603";
export var textbox = "how-to-order-module--textbox--be226";
export var toPlaceAn = "how-to-order-module--toPlaceAn--d986f";